import styled, { css } from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import BaseGrid from '@/components/UI/Grid/Grid'
import getTypeStyle from '@/utils/getTypeStyle'
import Link from '@/components/UI/Link/Link'
import Image from '@/components/UI/Image/Image'
import { SmallText } from '@/components/UI/Typography/Typography'

export const Wrapper = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${p =>
    p.variant === 'academy'
      ? p.theme.colors.white
      : `${p.theme.colors.white}04`};
  box-shadow: 0 0.25rem 2.5rem 0 ${p => p.theme.colors.maastricht}14;
  border-radius: 0.125rem;
  overflow: hidden;
  position: relative;
  transition: all ${p => p.theme.transitions.ease()};

  @media not all and (pointer: coarse) {
    &:hover,
    &:active {
      background: ${p =>
        p.variant === 'academy'
          ? p.theme.colors.maastricht
          : `${p.theme.colors.white}10`};
      color: ${p => p.theme.colors.white};
      ${SmallText} {
        color: ${p => p.theme.colors.white};
      }
    }
  }
`

export const Grid = styled(BaseGrid)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  ${p =>
    p.imagePosition &&
    css`
      ${above('md')`
      max-width: ${getColumnSize(5)};
    `}
      ${above('lg')`
      max-width: ${getColumnSize(4)};
    `}
    `}
`

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 20px 24px;

  svg {
    margin-top: auto;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const StyledImage = styled(Image)`
  display: block;
  object-fit: cover;
  object-position: center;
  min-height: 100%;
  height: 17rem;
  min-width: 100%;
  overflow: hidden;
`

export const TextWrapper = styled.div``

export const WrapperSchedule = styled.div`
  display: flex;
  margin: 32px 0px;
  justify-content: space-between;
  white-space: nowrap;
`

export const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemImage = styled(Image)`
  display: block;
  object-fit: cover;
  object-position: center;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50%;
`

export const Divider = styled.span`
  width: 1px;
  height: 100%;
  background: ${p => p.theme.colors.maastricht}10;
  margin: 0px 12px;
`

export const Title = styled.h3`
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  ${getTypeStyle('caption')};
`

export const CardDate = styled.div`
  color: ${p => p.theme.colors.gray};
  text-transform: uppercase;
  font-weight: 500;
  ${getTypeStyle('label')};
  margin-bottom: 0.75rem;
  ${Wrapper}:hover &,
  ${Wrapper}:focus &,
  ${Wrapper}:active & {
    color: ${p => p.theme.colors.white};
    opacity: 0.8;
  }
`
