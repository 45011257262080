const flatMap = (arr, fn = x => x) => {
  if (!Array.isArray(arr)) {
    return []
  }

  return arr?.reduce((acc, item) => {
    const data = fn(item)

    if (Array.isArray(data)) {
      return [...acc, ...data]
    }

    return [...acc, data]
  }, [])
}

export default flatMap
