import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding-bottom: 7.5rem;
  position: relative;
  ${above('md')`
    padding-bottom: 10rem;
  `}
`

export const TextWrapper = styled.div`
  margin-bottom: 2.5rem;
  ${above('md')`
    margin-bottom: 1.875rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  `}
`

export const Cards = styled.div`
  margin-bottom: 4rem;
  position: relative;
  z-index: 20;
  ${above('md')`
    margin-bottom: 7.5rem;
  `}
`

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 33px;
  align-items: stretch;
  height: 100%;
  ${above('md')`
  grid-template-columns: repeat(2, 1fr);
   `}
  ${above('lg')`
  grid-template-columns: repeat(3, 1fr);
  `}
`

export const CardCell = styled.div`
  height: auto;
  width: 100%;
`

export const Testimonials = styled.div`
  position: relative;
`

export const TestimonialGrid = styled(UI.Grid)`
  align-items: stretch;
`

export const TestimonialCell = styled.div`
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(8)};
    margin-left: ${getColumnSize(2)};
  `}
  ${above('md')`
    width: ${getColumnSize(8)};
    margin-left: ${getColumnSize(3)};
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
    margin-left: ${getColumnSize(4)};
  `}
  ${above('xg')`
    width: ${getColumnSize(5)};
  `}
`
