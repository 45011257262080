import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import { Section, TextWrapper } from './Academy.css'
import { Cell, Grid } from '../AcademyIndex/Featured.css'
import AcademyCard from '../AcademyIndex/AcademyCard'

const Academy = ({ content }) => {
  const featured =
    flatMap(content?.academy_featured, x => x?.academy?.document)?.map(x => ({
      ...x?.data,
    })) || []
  if (!featured.length) return null

  return (
    <Section>
      <UI.Container>
        <UI.SectionTitle>{content.academy_title}</UI.SectionTitle>
        <TextWrapper>
          <UI.SectionDescription>
            {content.academy_description}
          </UI.SectionDescription>
          <UI.ArrowLink color='yellow' to={content.academy_url}>
            {content.academy_see_all}
          </UI.ArrowLink>
        </TextWrapper>
        <Grid>
          {featured.map(x => (
            <Cell key={x.meta_title}>
              <AcademyCard featured={x} variant='home' />
            </Cell>
          ))}
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default Academy
