import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useContext } from '@/store'
import getLocalizedContent from '@/utils/getLocalizedContent'
import * as UI from '@/components/UI'
import {
  Section,
  TextCell,
  ImageCell,
  TextWrapper,
  Title,
  Description,
  ButtonsGroup,
  ButtonArrow,
  ItemsGrid,
  Item,
  ItemImage,
  ItemTitle,
  StyledBottom,
  BottomImage,
} from './About.css'

const contactQuery = graphql`
  query AboutQuery {
    content: allPrismicHome {
      edges {
        node {
          lang
          data {
            accredited_title
            accredited_description
            accredited_buttons {
              link
              main
              title
            }
            accredited_list {
              title
              image {
                url
              }
            }
            accredited_image {
              localFile {
                childImageSharp {
                  fluid {
                    aspectRatio
                    originalImg
                    originalName
                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const About = ({ bottomColor, ...props }) => {
  const { locale } = useContext()
  const data = useStaticQuery(contactQuery)
  const content = getLocalizedContent(data, locale)
  return (
    <Section {...props}>
      <UI.Container>
        <UI.Grid>
          <TextCell>
            <TextWrapper>
              <Title>{content.accredited_title}</Title>
              <Description>{content.accredited_description}</Description>
              <ButtonsGroup>
                {content.accredited_buttons.map(button => (
                  <UI.Button
                    key={button.title}
                    to={button.link}
                    hasHoverBlue
                    appearance='blue'
                  >
                    {button.title}
                    <ButtonArrow />
                  </UI.Button>
                ))}
              </ButtonsGroup>
            </TextWrapper>
          </TextCell>
        </UI.Grid>
        <ItemsGrid>
          {content.accredited_list.map(({ title, image }) => {
            return (
              <Item key={title}>
                <ItemImage src={image.url} alt={title} />
                <ItemTitle>{title}</ItemTitle>
              </Item>
            )
          })}
        </ItemsGrid>
        <UI.Grid>
          <ImageCell>
            <BottomImage
              alt={content.hero_title}
              src={content.accredited_image}
            />
          </ImageCell>
        </UI.Grid>
      </UI.Container>
      <StyledBottom color={bottomColor} />
    </Section>
  )
}

About.defaultProps = {
  bottomColor: 'snow',
}

export default About
