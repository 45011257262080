import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'

import srcHeart from '@/assets/images/nobox-red.png'
import srcMoon from '@/assets/images/nobox-green.png'
import srcYellow from '@/assets/images/nobox-yellow.png'
import srcCloud from '@/assets/images/nobox-blue.png'
import {
  Section,
  TextWrapper,
  Cards,
  CardGrid,
  CardCell,
  Testimonials,
  TestimonialGrid,
  TestimonialCell,
} from './Courses.css'

const Courses = ({ content }) => {
  const testimonials =
    flatMap(content?.courses_testimonials, x => x?.testimonial?.document)?.map(
      x => x?.data,
    ) || []

  const featured =
    flatMap(content?.courses_featured, x => x?.course?.document)?.map(x => ({
      ...x?.data,
      slug: x?.slug,
    })) || []

  return (
    <Section>
      <UI.Parallax top='-110px' right='50%' force={1.1} offsetComp={50}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(45deg)`,
            }}
            src={srcYellow}
            alt={content.courses_title}
            width='340'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax top='20%' left='-140px' force={1.2} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcCloud}
            alt={content.courses_title}
            width='340'
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <UI.SectionTitle>{content.courses_title}</UI.SectionTitle>
        <TextWrapper>
          <UI.SectionDescription>
            {content.courses_description}
          </UI.SectionDescription>
          <UI.ArrowLink color='orange' to={content.courses_url}>
            {content.courses_see_all}
          </UI.ArrowLink>
        </TextWrapper>
        <Cards>
          <CardGrid>
            {featured?.map(x => {
              return (
                <CardCell key={x.slug}>
                  <UI.Card
                    to={`${content.courses_url}/${x.slug}`}
                    color='orange'
                    title={x.meta_title}
                    description={x.meta_description}
                    image={x?.thumbnail}
                    tags={x.tags}
                    metricLabel={x.metric_label}
                    metricValue={x.metric_value}
                    metricDescription={x.metric_description}
                    isCourses
                  />
                </CardCell>
              )
            })}
          </CardGrid>
        </Cards>
        <Testimonials>
          <UI.Parallax top='-30px' right='110px' force={1.1} offsetComp={20}>
            {offSet => (
              <UI.ImageLoose
                style={{
                  transform: `translateY(${offSet}px) translateZ(0) rotate(180deg)`,
                }}
                src={srcHeart}
                alt={content.courses_title}
                width='360'
                hiddenMobile
              />
            )}
          </UI.Parallax>
          <UI.Parallax bottom='-30px' left='10%' force={1.1} offsetComp={20}>
            {offSet => (
              <UI.ImageLoose
                style={{
                  transform: `translateY(${offSet}px) translateZ(0) rotate(180deg)`,
                }}
                src={srcMoon}
                alt={content.courses_title}
                width='260'
              />
            )}
          </UI.Parallax>
          <TestimonialGrid>
            <TestimonialCell>
              <UI.TestimonialGroup testimonials={testimonials} color='orange' />
            </TestimonialCell>
          </TestimonialGrid>
        </Testimonials>
      </UI.Container>
    </Section>
  )
}

export default Courses
