import React from 'react'
import * as UI from '@/components/UI'
import srcBackground from '@/assets/images/nobox-hero-v2.png'
import {
  Section,
  TextCell,
  TextWrapper,
  Title,
  Description,
  Image,
  Wrapper,
} from './Hero.css'

const HomeHero = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <Wrapper>
          <TextCell>
            <TextWrapper>
              <Title>{content.hero_title}</Title>
              <Description>{content.hero_description}</Description>
              <UI.Group>
                {content.hero_buttons.map(button => (
                  <UI.Button
                    key={button.link}
                    to={button.link}
                    hasHoverRed={!button.main}
                    appearance={button.main ? 'blue' : 'ghost'}
                  >
                    {button.title}
                  </UI.Button>
                ))}
              </UI.Group>
            </TextWrapper>
          </TextCell>
          <Image src={srcBackground} />
        </Wrapper>
      </UI.Container>
    </Section>
  )
}

export default HomeHero
