import styled from 'styled-components'
import above from '@/utils/above'

export const Section = styled.section`
  overflow: hidden;
  position: relative;
  z-index: 20;
  background: ${p => p.theme.colors.maastricht};
  color: ${p => p.theme.colors.white};
  padding: 4rem 0 5rem;
  ${above('sm')`
    padding: 5rem 0 6rem;
  `}
  ${above('md')`
    padding: 6rem 0 8rem;
  `}
  ${above('lg')`
    padding: 7.5rem 0 10rem;
  `}
`

export const TextWrapper = styled.div`
  margin-bottom: 2.5rem;
  ${above('md')`
    margin-bottom: 1.875rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  `}
`
