import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import Hero from '@/components/contents/Home/Hero'
import SeriousGames from '@/components/contents/Home/SeriousGames'
import Courses from '@/components/contents/Home/Courses'
import Academy from '@/components/contents/Home/Academy'
import Articles from '@/components/contents/Home/Articles'
import About from '@/components/contents/shared/About/About'
import Contact from '@/components/contents/shared/Contact/Contact'
import { ContactWrapper } from '@/components/contents/ContactIndex/Contact.css'
import srcHeart from '@/assets/images/nobox-red.png'
import srcCloud from '@/assets/images/nobox-blue.png'
import * as UI from '@/components/UI'

const IndexPage = ({ data }) => {
  const content = data?.content?.data
  return (
    <Layout description={content.meta_description} image={content.meta_image}>
      <NavBar />
      <Hero content={content} />
      <SeriousGames content={content} />
      <Courses content={content} />
      <Academy content={content} />
      <About content={content} />
      <Articles content={content} />
      <ContactWrapper>
        <UI.Parallax top='10%' left='-140px' force={1.2} offsetComp={20}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcCloud}
              alt={content.meta_title}
              width='340'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <UI.Parallax bottom='30%' right='250px' force={1.2} offsetComp={20}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcHeart}
              alt={content.meta_title}
              width='340'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <Contact content={content} />
      </ContactWrapper>
      <Footer clientList />
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexPage($locale: String) {
    content: prismicHome(lang: { eq: $locale }) {
      lang
      data {
        articles_title
        articles_see_more
        articles_url
        articles_description
        articles_featured {
          article {
            document {
              ... on PrismicArticle {
                slug: uid
                data {
                  meta_title
                  meta_image {
                    url
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          originalImg
                          originalName
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                  meta_description
                  meta_image {
                    localFile {
                      childImageSharp {
                        fixed(width: 1200, height: 630) {
                          src
                        }
                      }
                    }
                  }
                  tags {
                    tag
                  }
                  date(formatString: "DD MMMM YYYY")
                }
              }
            }
          }
        }
        courses_description
        courses_see_all
        courses_title
        academy_title
        academy_url
        academy_see_all
        academy_description
        academy_featured {
          academy {
            document {
              ... on PrismicAcademy {
                data {
                  meta_title
                  meta_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                  type
                  type_description
                  duration
                  duration_value
                  link_text
                  link
                  trainer
                  trainer_name
                  trainer_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        hero_buttons {
          link
          main
          title
        }
        hero_description
        hero_image {
          url
          localFile {
            childImageSharp {
              fluid {
                aspectRatio
                originalImg
                originalName
                sizes
                src
                srcSet
              }
            }
          }
        }
        hero_title
        meta_description
        meta_title
        serious_games_card_description
        serious_games_card_title
        serious_games_description
        serious_games_featured {
          serious_game {
            document {
              ... on PrismicSeriousGame {
                slug: uid
                data {
                  meta_title
                  meta_description
                  meta_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                  tags {
                    tag
                  }
                }
              }
            }
          }
        }
        serious_games_see_all
        serious_games_title
        serious_games_url
        serious_games_card_link
        serious_games_testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  name
                  position
                  interview_text
                  interview_link
                  content {
                    html
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          originalName
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        courses_url
        courses_featured {
          course {
            document {
              ... on PrismicCourse {
                slug: uid
                data {
                  meta_title
                  meta_description
                  tags {
                    tag
                  }
                  metric_label
                  metric_value
                  metric_description
                  thumbnail {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          src
                          sizes
                          originalName
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        courses_testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  name
                  position
                  interview_text
                  interview_link
                  content {
                    html
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          originalName
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
