import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  overflow: hidden;
`

export const TextCell = styled.div`
   width: ${getColumnSize(12)};
   ${above('md')`
     width: ${getColumnSize(6)};
   `}
   ${above('lg')`
     width: ${getColumnSize(5)};
   `}
   ${above('xg')`
     width: ${getColumnSize(6)};
   `}
`

export const TextWrapper = styled.div`
  position: relative;
  margin: 1.5rem 0;
  z-index: 9;
  ${above('sm')`
    margin: 2rem 0;
  `}
  ${above('md')`
    margin: 4rem 0;
  `}
`

export const Title = styled(UI.PageTitle)`
  position: relative;
  ${getTypeStyle('title')};
  margin-bottom: 1rem;

  ${above('md')`
    margin-bottom: 1.5rem;
    max-width: 516px;
  `}
`

export const Description = styled(UI.SectionDescription)`
  margin-bottom: 2rem;
`

export const Image = styled.img`
  position: relative;
  margin-top: 60px;

  ${above('md')`
 margin-top: -64px;
 `}

  ${above('xg')`
  margin-top: -400px;
 
`}
`

export const Wrapper = styled.div`
  position: relative;

  margin-bottom: 80px;

  ${above('md')`
  min-height: 62vw;
  margin-bottom: 120px;
  `}

  ${above('lg')`
  min-height: 46vh;
  `}
`
