import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding: 5rem 0 1.5rem 0;
  position: relative;
  z-index: 25;
  ${above('md')`
    padding: 8rem 0 5rem;
  `}
  ${above('xg')`
    padding-top: 12.5rem;
  `}
`

export const TextWrapper = styled.div`
  margin-bottom: 2.5rem;
  ${above('md')`
    margin-bottom: 1.875rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  `}
`

export const Cards = styled.div``

export const CardGrid = styled(UI.Grid)`
  align-items: stretch;
  height: 100%;
  z-index: 20;
`

export const CardCell = styled.div`
  height: auto;
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
  ${above('xg')`
    width: ${getColumnSize(3)};
  `}
`
