import React from 'react'
import { SmallText, Label } from '@/components/UI/Typography/Typography'
import * as UI from '@/components/UI'
import {
  Wrapper,
  Grid,
  Inner,
  Main,
  TextWrapper,
  ImageWrapper,
  StyledImage,
  Title,
  WrapperSchedule,
  WrapperItem,
  Divider,
  ItemImage,
} from './Card.css'
import { truncate } from '../../../utils/truncate'

const AcademyCard = ({ featured, variant, ...props }) => (
  <Wrapper variant={variant} to={featured.link} {...props}>
    <Grid>
      {featured.meta_image && (
        <ImageWrapper>
          <StyledImage alt={featured.meta_title} src={featured.meta_image} />
        </ImageWrapper>
      )}
      <Inner>
        <Main>
          {(featured.meta_title || featured.meta_description) && (
            <TextWrapper>
              {featured.meta_title && <Title>{featured.meta_title}</Title>}
              {featured.meta_description && (
                <SmallText>{truncate(featured.meta_description, 82)}</SmallText>
              )}
            </TextWrapper>
          )}
          <WrapperSchedule>
            <WrapperItem>
              <Label color='gray' style={{ marginBottom: '4px' }}>
                {featured.type}
              </Label>
              <SmallText>{featured.type_description}</SmallText>
            </WrapperItem>
            <Divider />
            <WrapperItem>
              <Label color='gray' style={{ marginBottom: '4px' }}>
                {featured.duration}
              </Label>
              <SmallText>{featured.duration_value}</SmallText>
            </WrapperItem>
            <Divider />
            <WrapperItem>
              <Label color='gray' style={{ marginBottom: '4px' }}>
                {featured.trainer}
              </Label>
              <div style={{ display: 'flex' }}>
                <ItemImage
                  alt={featured.trainer}
                  src={featured.trainer_image}
                />
                <SmallText style={{ marginLeft: '8px' }}>
                  {featured.trainer_name}
                </SmallText>
              </div>
            </WrapperItem>
          </WrapperSchedule>
          <UI.Button
            to={featured.link}
            hasHoverDark
            appearance='yellow'
            style={{ width: '100%' }}
          >
            {featured.link_text}
          </UI.Button>
        </Main>
      </Inner>
    </Grid>
  </Wrapper>
)

export default AcademyCard
