import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import srcYellow from '@/assets/images/nobox-yellow.png'
import { Section, TextWrapper, Cards, CardGrid, CardCell } from './Articles.css'

const Articles = ({ content }) => {
  const featured =
    flatMap(content?.articles_featured, x => x?.article?.document)?.map(x => ({
      ...x?.data,
      slug: x?.slug,
    })) || []

  return (
    <Section>
      <UI.Parallax bottom='20%' right='30%' force={1.1} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(-135deg)`,
            }}
            src={srcYellow}
            alt={content.meta_title}
            width='360'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <UI.SectionTitle>{content.articles_title}</UI.SectionTitle>
        <TextWrapper>
          <UI.SectionDescription>
            {content.articles_description}
          </UI.SectionDescription>
          <UI.ArrowLink color='aqua' to={content.articles_url}>
            {content.articles_see_more}
          </UI.ArrowLink>
        </TextWrapper>
        <Cards>
          <CardGrid vertical-gutter>
            {featured.map(x => (
              <CardCell key={x.slug}>
                <UI.Card
                  to={`${content.articles_url}/${x.slug}`}
                  color='aqua'
                  title={x.meta_title}
                  date={x.date}
                  description={x.meta_description}
                  image={x?.meta_image}
                  tags={x.tags}
                  type='article'
                />
              </CardCell>
            ))}
          </CardGrid>
        </Cards>
      </UI.Container>
    </Section>
  )
}

export default Articles
