import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import srcHeart from '@/assets/images/nobox-red.png'
import {
  Section,
  TextWrapper,
  Cards,
  CardGrid,
  CardCell,
} from './SeriousGames.css'

const SeriousGames = ({ content }) => {
  const featured =
    flatMap(
      content?.serious_games_featured,
      x => x?.serious_game?.document,
    )?.map(x => ({ ...x?.data, slug: x?.slug })) || []

  return (
    <Section>
      <UI.Container>
        <UI.SectionTitle style={{ zIndex: '99', position: 'relative' }}>
          {content.serious_games_title}
        </UI.SectionTitle>
        <TextWrapper>
          <UI.SectionDescription>
            {content.serious_games_description}
          </UI.SectionDescription>
          <UI.ArrowLink color='blue' to={content.serious_games_url}>
            {content.serious_games_see_all}
          </UI.ArrowLink>
        </TextWrapper>
        <Cards>
          <UI.Parallax top='12%' left='-150px' force={1.1} offsetComp={20}>
            {offSet => (
              <UI.ImageLoose
                style={{
                  transform: `translateY(${offSet}px) translateZ(0)`,
                }}
                src={srcHeart}
                alt={content.serious_games_title}
                width='360'
              />
            )}
          </UI.Parallax>
          <CardGrid vertical-gutter>
            {featured.map(x => (
              <CardCell>
                <UI.Card
                  to={`${content.serious_games_url}/${x.slug}`}
                  color='blue'
                  title={x.meta_title}
                  description={x.meta_description}
                  image={x?.meta_image}
                  tags={x.tags}
                />
              </CardCell>
            ))}
            <CardCell>
              <UI.Card
                fixColorInMobile
                color='blue'
                to={content.serious_games_card_link}
                title={content.serious_games_card_title}
                description={content.serious_games_card_description}
              >
                <UI.Icon.Plus size={32} />
              </UI.Card>
            </CardCell>
          </CardGrid>
        </Cards>
      </UI.Container>
    </Section>
  )
}

export default SeriousGames
