import styled from 'styled-components'
import above from '@/utils/above'

export const Section = styled.section`
  padding-bottom: 6.5rem;
  position: relative;
  z-index: 20;
  ${above('md')`
    padding-bottom: 7.5rem;
  `}
  ${above('lg')`
    padding-bottom: 11.25rem;
  `}
`

export const Grid = styled.div`
  position: relative;
  z-index: 999;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 33px;
  align-items: stretch;
  height: 100%;
  ${above('md')`
grid-template-columns: repeat(2, 1fr);
 `}
  ${above('xg')`
grid-template-columns: repeat(3, 1fr);
`}
`

export const Cell = styled.div`
  height: auto;
  width: 100%;
`
