import React from 'react'
import styled from 'styled-components'
import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  overflow: hidden;
  position: relative;
  background: ${p => p.theme.colors.flash};
  color: ${p => p.theme.colors.maastricht};
  padding-top: 5rem;
  ${above('md')`
    padding-top: 7.5rem;
  `}
`

export const TextCell = styled.div`
  margin: 0 auto;
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(10)};
  `}
  ${above('lg')`
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    width: ${getColumnSize(6)};
  `}
`

export const ImageCell = styled.div`
  margin: auto;
   width: ${getColumnSize(12)};
   ${above('md')`
     width: ${getColumnSize(10)};
   `}
   ${above('lg')`
     width: ${getColumnSize(8)};
   `}
   ${above('xg')`
     width: ${getColumnSize(6)};
   `}
`

export const TextWrapper = styled.div`
  text-align: center;
  margin-bottom: 1.75rem;
  ${above('md')`
    margin-bottom: 3.5rem;
  `}
`

export const Title = styled.h2`
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin-bottom: 1.5rem;
  ${getTypeStyle('title')};
  ${above('lg')`
    ${getTypeStyle('title')};
  `}
`

export const Description = styled.p`
  margin-bottom: 2rem;
  ${getTypeStyle('smallText')};
  ${above('lg')`
    ${getTypeStyle('text')};
  `}
`

export const ButtonsGroup = styled(UI.Group)`
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.blue};
`

export const ButtonArrow = styled(UI.Icon.Arrow)`
  margin: 0 -0.125rem 0 0.5rem;
`

export const ItemsGrid = styled(UI.Grid)`
  justify-content: center;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin: 1.5rem 0;
  ${getTypeStyle('blogText')};
  width: ${getColumnSize(6)};
  ${above('md')`
    ${getTypeStyle('text')};
    width: ${getColumnSize(4)};
  `}
  ${above('lg')`
    ${getTypeStyle('subtitle')};
    width: ${getColumnSize(3)};
  `}
`

export const ItemImage = styled(UI.Image)`
  width: 4rem;
  height: 4rem;
  ${above('xg')`
  width: 4.5rem;
  height: 4.5rem;
  `}
  ${above('lg')`
    width: 5rem;
    height: 5rem;
  `}
`

export const ItemTitle = styled.h3`
  text-align: center;
  ${getTypeStyle('blogText')};
  margin-top: 0.5rem;
  max-width: 10em;
  ${above('md')`
    ${getTypeStyle('text')};
    margin-top: 0.75rem;
  `}
  ${above('xg')`
    ${getTypeStyle('subtitle')};
    margin-top: 1rem;
  `}
`

const Bottom = props => (
  <svg viewBox='0 0 1605 162' {...props}>
    <path
      fill='currentColor'
      d='M814.93801 160h.00045c24.45206 0 48.84953-.46762 73.18147-1.41107C1127.60524 149.33137 1369.75742 93.59138 1604.77365 0H1605v162H0V.1468C268.50482 103.03177 545.04717 159.99996 814.93801 160z'
    />
  </svg>
)

export const StyledBottom = styled(Bottom)`
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${p => p.theme.colors[p.color]};
`

export const BottomImage = styled(UI.Image)`
  display: block;
  margin-top: 5rem;
`
